/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import favicon100 from "../images/favicon.png";
import ogImage from "../images/globe.png";

function SEO({ description, lang, meta, title }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            domain
            ogImage
            author
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={title}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: "title",
          property: "og:title",
          content: `${title}`
        },
        {
          name: "image",
          property: "og:image",
          content: `${site.siteMetadata.domain}${ogImage}`
        },
        {
          name: "imagealt",
          property: "og:image:alt",
          content: `Earth at night`
        },
        // {
        //   property: `og:title`,
        //   content: title,
        // },
        // {
        //   property: `og:description`,
        //   content: metaDescription,
        // },
        {
          name: "author",
          content: `zapteck.com`,
        },
        // {
        //   name: `twitter:card`,
        //   content: `summary`,
        // },
        // {
        //   name: `twitter:creator`,
        //   content: '@leonbarnard',
        // },
        // {
        //   name: `twitter:title`,
        //   content: title,
        // },
        // {
        //   name: `twitter:description`,
        //   content: metaDescription,
        // },
      ].concat(meta)}
      link={[
        { rel: 'icon', type: 'image/png', sizes: "16x16", href: `${favicon100}` },
      ]}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: `test description`,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
