/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import Header from "./header";

const Layout = ({ children }) => {
  return (
    <>
      <Header />
      <main id="content">{children}</main>
      <footer>
        <a href="/terms">Terms</a> | <a href="/privacy">Privacy</a> | <a href="/accessibility">Accessibility</a>
        <br />
        &copy; Better Access, LLC.
      </footer>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
