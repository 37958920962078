import PropTypes from "prop-types"
import React from "react"
import { Navbar } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faUniversalAccess } from '@fortawesome/free-solid-svg-icons'

const Header = ({ siteTitle }) => (
  <>
  <a className="skip-link screen-reader-text" href="#content">Skip to content</a>
  <h1 className="visuallyhidden">Better Access</h1>
  <header className="bg-light">
        <Navbar bg="light" expand="lg" className="">
          <Navbar.Brand className="site-logo blue-grey" href="/">
            <FontAwesomeIcon icon={faUniversalAccess}></FontAwesomeIcon>
            <span className="logo-text titleCase">B</span>
            <span className="logo-text">etter</span>
            <span className="logo-text titleCase">A</span>
            <span className="logo-text">ccess</span>
          </Navbar.Brand>
        </Navbar>
  </header>
  </>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: `BetterAccess`
}

export default Header
